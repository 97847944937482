<template>
    <CCard>
        <teleport v-if="mountedComponent" to="#header-nav">
            <CBreadcrumbItem active>
                <span>Tasks</span>
            </CBreadcrumbItem>
        </teleport>

        <CCardBody>
            <CAccordion style="position: relative" :active-item-key="1">
                <AccordionItem :mobile-label="'Tasks'" :item-key="1">
                    <div class="tasksList__filter">
                        <CRow class="gx-0 gx-lg-3 justify-content-start w-100 mb-3">
                            <CCol class="GCFilterMaxWidth col-12 col-md-12 col-lg-7 col-xl-9 col-xxl-9">
                                <GCFilter component="Tasks" :except-column="exceptColumn"
                                          @search="(val) => fetchData(val)"/>
                            </CCol>
                            <CCol
                                class="bulkAction col-12 col-md-6 col-lg-3 col-xl-2 col-xxl-2 text-lg-center mt-3 mt-lg-0">
                                <CDropdown :key="`${selectedTasks.length}`" color="primary" class="w-100">
                                    <CDropdownToggle class="taskBulkAction" color="primary"
                                                     :disabled="!selectedTasks.length" variant="outline"
                                                     @click="clickBulkAction">
                                        Bulk Action
                                    </CDropdownToggle>
                                    <CDropdownMenu>
                                        <CDropdownItem @click="handleEditTask">Edit Properties</CDropdownItem>
                                        <CDropdownItem @click="eventRevokeTasks">Revoke</CDropdownItem>
                                    </CDropdownMenu>
                                </CDropdown>
                            </CCol>
                        </CRow>
                    </div>
                    <UpdateTasks v-if="editTasks" :tasks="selectedTasks" @close="closeEdit"/>
                    <SmartTable :items="tasks" :items-per-page="parseInt(perPage)" :columns="fields"
                                :sorter-value="sort" selectable="id" :selected-items="selectedTasks"
                                :loading="loadingTable" :active-page="activePage" :per-page="perPage" :pages="pages"
                                :clear-text=" search.length ? 'No search results found' : 'No Data yet'"
                                @sorter-change="onSort" @selected-items-change="(s) => (selectedTasks = s)"
                                @update-active-page="(value) => (activePage = value)"
                                @update-per-page="(value) => (perPage = value)">
                        <template #task_name_with_count="{ item }">
                            <COverdueTooltip v-if="item.is_overdue" :overdue-date="item.due_date"/>
                            <CButton color="link" size="sm" style="color: #0068ad" @click="goToView(item, false)">
                                <CIcon name="cilWindowRestore" @click.stop="goToView(item, true)"/>
                                {{ item.task_name_with_count }}
                            </CButton>
                        </template>

                        <template #clear_text>
                            <NoTasks/>
                        </template>

                        <template #status="{ item, column }">
                            <CBadge v-if="column.key === 'status'" style="font-size: 12px; display: block; width: 100%"
                                    color="primary">
                                {{ item.status }}
                            </CBadge>
                        </template>
                    </SmartTable>
                </AccordionItem>
            </CAccordion>
        </CCardBody>
    </CCard>
</template>

<script src="./script.js"></script>
<style lang="scss">
@import 'style';

.table__clear {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 30px 30px 60px;
    background: #f5f7f9;

    &__icon {
        color: #b1b7c1;
        margin-bottom: 20px;
    }

    &__title {
        margin-bottom: 12px;
        font-weight: 700;
        font-size: 18px;
        color: #303c54;

        &-sub {
            margin-bottom: 35px;
            font-weight: 400;
            font-size: 14px;
            color: #465967;
        }
    }
}
</style>
