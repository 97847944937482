<template>
    <h5>Edit Select Tasks</h5>
    <div class="updateTasks__edit">
        <div :style="{ maxWidth: '300px', width: '300px', margin: '0 10px 10px 0' }">
            <CInputDate v-model="due_date" placeholder="Calendar Due Date" :type="'clear'" :error="errors.due_date"
                        @touchField="() => validate('due_date')"/>
        </div>
        <CSelectSearch v-model="priority"
                       :options="[ { value: 'High', label: 'High' }, { value: 'Normal', label: 'Normal' } ]"
                       :placeholder="'Priority'" :style="{ maxWidth: '300px', width: '300px', margin: '0 10px 10px 0' }"
                       :error="errors.priority" @touchField="() => validate('priority')"/>
        <CSelectSearch v-model="internal_assignee"
                       :options=" internal_assignee_list.map((i) => ({ value: i.id, label: i.full_name }))"
                       :placeholder="'Internal Assignee'"
                       :style="{ maxWidth: '300px', width: '300px', margin: '0 10px 10px 0' }"
                       :error="errors.internal_assignee" @touchField="() => validate('internal_assignee')"/>
        <div>
            <div class="updateTasks__edit__btn">
                <CButton color="primary" @click="saveTasks">Save</CButton>
                <CButton color="primary" variant="outline" @click="$emit('close')">Cancel</CButton>
            </div>
            <span style="color: red; margin-top: 0.25rem; font-size: 0.875em">{{ errors.tasks }}</span>
        </div>
    </div>
</template>

<script>
import CSelectSearch from '@/components/CSelectSearch'
import CInputDate from '@/components/CInputDate'
import errors from '@/mixin/errors'

export default {
    components: {CSelectSearch, CInputDate},
    mixins: [errors],
    inject: ['toast'],
    props: {
        tasks: {type: Array, default: []},
    },
    emits: ['close'],
    data() {
        return {
            due_date: null,
            priority: null,
            internal_assignee: null,
            reminders: null,
            internal_assignee_list: [],
            ruleFields: [
                {key: 'reminders', rules: []},
                {key: 'internal_assignee', rules: []},
                {key: 'priority', rules: []},
                {key: 'due_date', rules: []},
                {
                    key: 'tasks',
                    rules: [
                        {
                            name: 'custom',
                            function: () => (this.tasks.length ? null : 'Select Tasks'),
                        },
                    ],
                },
            ],
        }
    },
    mounted() {
        this.errors = {}
        this.due_date = null
        this.priority = null
        this.internal_assignee = null
        this.reminders = null

        this.$http.companies
            .getCompanyUsers({
                params: {
                    search: '',
                    permission: 'task_and_documents_sign',
                },
            })
            .then((res) => {
                this.internal_assignee_list = res.data.data
            })
    },
    methods: {
        saveTasks() {
            if (this.validate()) {
                this.errors = {}
                let data = {task_id: this.tasks}
                if (this.due_date) data.due_date = this.$FtoB({value: this.due_date})
                if (this.priority) data.priority = this.priority
                if (this.internal_assignee)
                    data.internal_assignee_id = this.internal_assignee
                if (this.reminders === 0 || this.reminders === 1)
                    data.reminders_enabled = this.reminders
                this.$http.tasks
                    .editTasksMass(data)
                    .then(() => {
                        this.toast('info', 'The tasks have been updated successfully')
                        this.$emit('close')
                    })
                    .catch(({response}) => {
                        this.errors = response.data.errors
                            ? this.getErrors(response.data.errors)
                            : {}

                        response.status === 403
                            ? this.toast('warning', 'It is impossible to edit tasks assigned to your company')
                            : this.toast('warning', response.data.message)
                    })
            }
        },
    },
}
</script>

<style lang="scss" scoped>
.updateTasks {
    &__edit {
        display: flex;
        align-items: flex-start;

        @media (max-width: 1200px) {
            flex-wrap: wrap;
        }

        &__btn {
            display: flex;

            button {
                margin-right: 10px;
            }
        }
    }
}
</style>

<style>
.updateTasks__edit .form-control {
    font-size: 14px !important;
    padding-top: 0.58rem;
    font-weight: 500;
}

.updateTasks__edit .form-control::-webkit-input-placeholder {
    color: var(--cui-body-color, rgba(44, 56, 74, 0.95));
}
</style>
